import React from 'react'
import { styles } from '../../../style'
import { MdCookie } from "react-icons/md";

const Index = () => {
  return (
    <div className='dashboard-main'>
      <div className="dashboard-cookie-policy-wrapper">
        <div className={`dashboard-cookie-policy-heading ${styles.heading4}`}>
          <MdCookie className='dashboard-cookie-policy-heading-icon'/> manage cookie policy
        </div>
        <div className="dashboard-cookie-policy-content">
          This is cookie policy management content
        </div>
      </div>
    </div>
  )
}

export default Index