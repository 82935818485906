import React from 'react'

const TableCount = (props) => {
  return (
    <div className='table-count-wrapper'>
        Total<span>{props.count}</span>{props.name}
    </div>
  )
}

export default TableCount