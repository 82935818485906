import React from 'react';
import { Link } from 'react-router-dom';
import { RiArrowGoBackFill } from 'react-icons/ri';

const NotFound = ({ code }) => {
	return (
		<div className="not-found-container">
			<div className="not-found-code">{code}</div>
			<div className="not-found-title">not found</div>
			<div className="not-found-msg">
				{code === '404'
					? 'sorry! The requested page could not be found'
					: 'Unauthorized access'}
			</div>
			<div className="not-found-home-link">
				<RiArrowGoBackFill />
				<Link to="/">home</Link>
			</div>
		</div>
	);
};

export default NotFound;
