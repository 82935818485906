import React from 'react';
import { Link } from 'react-router-dom';
import { BiCheckShield } from 'react-icons/bi';
import { styles } from '../../../../style';
import { browser4, cookie, circle, laptop } from '../../../../assets/images';

const CookiePolicy = () => {
	return (
		<div className="policy-page">
			<div
				className={`policy-page-banner ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}
			>
				<div className="policy-page-banner-left">
					<div className="policy-page-banner-heading">
						Cookie Policy{' '}
						<span className="policy-page-banner-heading-line"></span>
					</div>

					<div
						className={`policy-page-banner-description ${styles.paragraph1}`}
					>
						Welcome to <span>dasangsherlaam.com</span>. This Cookie Policy
						explains how we use cookies and similar tracking technologies on our
						website. By using our website, you consent to the use of cookies as
						described in this policy.
					</div>
					<div className="policy-page-banner-btn">
						<a href="#policy-page-banner-btn-target" className="btn-primary">
							Learn more
						</a>
					</div>
				</div>
				<div className="policy-page-banner-right">
					<div className="policy-page-banner-right-img-container">
						<img
							className="policy-page-banner-right-img-laptop"
							src={laptop}
							alt=""
						/>
						<div className="policy-page-banner-right-img-circle-container">
							<img
								className="policy-page-banner-right-img-circle"
								src={circle}
								alt=""
							/>
						</div>
						<div className="policy-page-banner-right-img-cookie-container">
							<img
								src={cookie}
								alt=""
								className="policy-page-banner-right-img-cookie"
							/>
						</div>
						<div className="policy-page-banner-right-img-browser4-container">
							<div className="policy-page-banner-right-img-browsers">
								<img
									src={browser4}
									alt=""
									className="policy-page-banner-right-img-browser4"
								/>
							</div>
						</div>
					</div>
				</div>
				<div id="policy-page-banner-btn-target">cookie policy btn target</div>
			</div>
			<div
				className={`policy-page-content  ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}
			>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">1. what are cookies ?</span>
							<div className="policy-page-content-heading-tag-text">
								1. what are cookies ?
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<div className="policy-page-content-text-list-description">
								Cookies are small pieces of data stored on your device (computer
								or mobile device) when you visit a website. They are widely used
								to make websites work efficiently and provide a better browsing
								experience.
							</div>
						</div>
					</div>
				</div>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">2. types of cookies we use</span>
							<div className="policy-page-content-heading-tag-text">
								2. types of cookies we use
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<div className="policy-page-content-text-bullets">
								<div
									className={`policy-page-content-text-bullet ${styles.paragraph3}`}
								>
									<div className="policy-page-content-text-bullet-symbol">
										<BiCheckShield />
									</div>
									<div className="policy-page-content-text-bullet-text">
										<span>Essential Cookies:</span> These cookies are necessary
										for the website to function properly. They enable basic
										features such as page navigation, accessing secure areas,
										and providing services like shopping carts.
									</div>
								</div>
							</div>
							<div className="policy-page-content-text-bullets">
								<div
									className={`policy-page-content-text-bullet ${styles.paragraph3}`}
								>
									<div className="policy-page-content-text-bullet-symbol">
										<BiCheckShield />
									</div>
									<div className="policy-page-content-text-bullet-text">
										<span>Functional Cookies:</span> These cookies allow the
										website to remember your preferences, such as language
										settings and font size, to provide you with a more
										personalized experience.
									</div>
								</div>
							</div>
							<div className="policy-page-content-text-bullets">
								<div
									className={`policy-page-content-text-bullet ${styles.paragraph3}`}
								>
									<div className="policy-page-content-text-bullet-symbol">
										<BiCheckShield />
									</div>
									<div className="policy-page-content-text-bullet-text">
										<span>Performance Cookies:</span> These cookies help us
										understand how visitors use our website by collecting
										information about the pages they visit and any errors
										encountered. This data helps us improve our website's
										performance.
									</div>
								</div>
							</div>
							<div className="policy-page-content-text-bullets">
								<div
									className={`policy-page-content-text-bullet ${styles.paragraph3}`}
								>
									<div className="policy-page-content-text-bullet-symbol">
										<BiCheckShield />
									</div>
									<div className="policy-page-content-text-bullet-text">
										<span>Targeting or advertising Cookies:</span> We may use
										these cookies to deliver relevant advertisements to you
										based on your interests and browsing behavior. They also
										help us measure the effectiveness of our advertising
										campaigns.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">3. third party cookies</span>
							<div className="policy-page-content-heading-tag-text">
								3. third party cookies
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<div className="policy-page-content-text-list-description">
								We may also allow third-party service providers to use cookies
								on our website. These cookies enable them to gather information
								about your browsing habits across different websites, which they
								may use for advertising and analytics purposes.
							</div>
						</div>
					</div>
				</div>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">4. managing cookies</span>
							<div className="policy-page-content-heading-tag-text">
								4. managing cookies
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<div className="policy-page-content-text-list-description">
								Most web browsers allow you to manage your cookie preferences.
								You can usually set your browser to block or delete cookies.
								Keep in mind that if you choose to disable cookies, some parts
								of our website may not function properly.
							</div>
						</div>
					</div>
				</div>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">5. your consent</span>
							<div className="policy-page-content-heading-tag-text">
								5. your consent
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<div className="policy-page-content-text-list-description">
								<div className="policy-page-content-text-list-description">
									By using our website, you consent to the use of cookies as
									described in this Cookie Policy. You also agree to our Privacy
									Policy
									<Link
										to="/privacy-policy"
										className="ml-2 text-colorPrimary font-semibold underline"
									>
										Privacy Policy
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">6. updates to this policy</span>
							<div className="policy-page-content-heading-tag-text">
								6. updates to this policy
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<div className="policy-page-content-text-list-description">
								We may update this Cookie Policy from time to time to reflect
								changes in technology or legal requirements. Any updates will be
								posted on this page, and the "Last Updated" date will be revised
								accordingly.
							</div>
						</div>
					</div>
				</div>
				<div className={`policy-page-content-section ${styles.paddingY}`}>
					<div className="policy-page-content-heading-tag">
						<span className="policy-page-content-heading-tag-border">
							<span className="invisible">7. contact us</span>
							<div className="policy-page-content-heading-tag-text">
								7. contact us
							</div>
						</span>
					</div>
					<div className="policy-page-content-text">
						<div className="policy-page-content-text-list">
							<div className="policy-page-content-text-list-description">
								If you have any questions about our Cookie Policy or our use of
								cookies, please contact us at
								<Link
									to="/contact"
									className="ml-2 text-colorPrimary font-semibold underline"
								>
									Contact Us
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CookiePolicy;
