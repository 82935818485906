import React from 'react'
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const Pagination = (props) => {

    return (
        <div className='pagination-wrapper'>
            <span className={props.currentPage === 1 ? 'pagination-first pagination-disabled' : 'pagination-first'} onClick={() => props.setCurrentPage(1)}>
                first
            </span>
            <span className={props.currentPage === 1 ? 'pagination-previous pagination-disabled' : 'pagination-previous'} onClick={() => props.setCurrentPage(props.currentPage - 1) }>
                <FaAngleLeft/> 
            </span>
            <span className="pagination-current">
                <span>Page</span>
                <span>
                    {props.currentPage}
                </span>
                <span>of&nbsp; {props.totalPage}</span>
            </span>
            <span className={props.currentPage === props.totalPage ? 'pagination-next pagination-disabled' : 'pagination-next'} onClick={() => props.setCurrentPage(props.currentPage + 1)}>
                <FaAngleRight/>
            </span>
            <span className={props.currentPage >= props.totalPage ? 'pagination-last pagination-disabled' : 'pagination-last'} onClick={() => props.setCurrentPage(props.totalPage)}>
                last
            </span>
        </div>
    )
}

export default Pagination