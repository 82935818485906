import React, {useState, useRef, useEffect} from 'react'
import { styles } from '../../../style';
import { HiMiniUserGroup } from "react-icons/hi2";
import { Table, TableSearch, TableCount, TableRowLimit, Pagination, FilterReset, UserPreview } from '../../../components/dashboard';
import {users} from '../../../constants';
import { IoFilter } from "react-icons/io5";

const Index = () => {

 	 //Define the number of rows for table to be displayed
	const[rows, setRows] = useState(10);

  	//Define the search query string for table data
	const[searchQuery, setSearchQuery] = useState('');

  	//Define the number of table rows to be selected from dropdown
	const rowOptions = [10, 20, 30, 40, 50];

  	//Define filter options status or each filter
	const [isSocialFilter, setIsSocialFilter] = useState(false);
	const [isSubscriptionFilter, setIsSubscriptionFilter] = useState(false);

	//Define table filter title references
	const socialFilterTitleRef = useRef();
	const subscriptionFilterTitleRef = useRef();

  	//Define seleteced filter options array
	const [selectedFilters, setSelectedFilters] = useState({
		social: '',
		subscription: '',
	});
  
	//Define filter reset status
	const [isResetFilter, setIsResetFilter] = useState(false);

  	//Define current page & no of pages for pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(5);

  	//Method to get the selected filter option
	const getSelectedFilter = (title,option) => {
		setSelectedFilters({...selectedFilters, [title] : option})
	} 

  	//Define categroy preview status
	const [isUserView, setIsUserView] = useState(false);
	const [currentUserView, setCurrentUserView] = useState('');


  	//Define table heads
	const tableColumn = [
		{
			id: 1,
			title: 'id',
			filter: false,
		},
		{
			id: 2,
			title: 'image',
			filter: false,
		},
		{
			id:3,
			title: 'name',
			filter: false,
		},
		{
			id:4,
			title: 'email',
			filter: false,
		},
		{
			id:5,
			title: 'role',
			filter: false,
		},
		{
			id:6,
			title: 'social',
			filter: true,
      filterOptions: ['facebook', 'google'],
      filterStatus: isSocialFilter,
      setFilterStatus: setIsSocialFilter,
      icon: <IoFilter/>,
      titleRef: socialFilterTitleRef
		},
    {
      id: 7,
      title: 'subscription',
      filter: true,
      filterOptions: ['yes', 'no'],
      filterStatus: isSubscriptionFilter,
      setFilterStatus: setIsSubscriptionFilter,
      icon: <IoFilter/>,
      titleRef: subscriptionFilterTitleRef
    },
    {
      id: 8,
      title: 'actions',
      filter: false,
    }
  ];

  useEffect(() => {
		//Check if any of filter option is selected to show filter reset button
		if(selectedFilters.social !== '' || selectedFilters.subscription !== '') {
			setIsResetFilter(true);
		}

		//Hide filter options on outside click
		//Define call back function on outside click
		const handleOutsideTitleClick = (e) => {
			//Define action on each icon outside click
			tableColumn.forEach((col) => {
				if(col.filterStatus && !col.titleRef.current.contains(e.target)) {
					col.setFilterStatus(false);
				}
			});
		};

		//Define on click
		document.addEventListener('click', handleOutsideTitleClick);

		//Remove event listener
		return () => {
			document.removeEventListener('click', handleOutsideTitleClick);
		}
		// eslint-disable-next-line
	},[isSocialFilter, isSubscriptionFilter]);

	//Method to reset filter 
	const onClickResetFilter = () => {
		setSelectedFilters({
			social: '',
			subscription: '',
		});
		setIsResetFilter(false);
	}

	//Handle user view
	const handleUserView = () => {
		setIsUserView(false);
	}

	//Handlers for view & delete
	const handleOnViewClick = (user) => {
		setIsUserView(true);
		setCurrentUserView(user);
	}

	const handleOnDeleteClick = (id) => {
		alert('id ' + id + ' requested for delete');
	}

	console.log(currentUserView);

	return (
		<div className='dashboard-main'>
			<div className="dashboard-main-wrapper">
				<div className={`dashboard-main-heading ${styles.heading4}`}>
					<HiMiniUserGroup className='dashboard-main-heading-icon'/> manage users
				</div>
				<div className="dashboard-main-content">
					<div className="dashboard-main-content-header">
						<div className="dashboard-main-content-header-left">
							<TableSearch setSearchQuery={setSearchQuery} searchTag="name"/>
							<FilterReset isResetFilter={isResetFilter} onClickResetFilter={onClickResetFilter}/>
						</div>
						<div className="dashboard-main-content-header-right">
							<TableCount count={users.length} name="users"/>
							<TableRowLimit rows={rows} setRows={setRows} rowOptions={rowOptions}/>
						</div>
					</div>
					<div className="dashboard-main-content-data">
						<Table tableColumn={tableColumn} tableData={users} tableDataType="users" getSelectedFilter={getSelectedFilter} handleOnViewClick={handleOnViewClick} handleOnDeleteClick={handleOnDeleteClick}/>
						<Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} />
					</div>
				</div>
				{
					isUserView && <UserPreview user={currentUserView} handleUserPreview={handleUserView}/>
				}
			</div>
		</div>
	)
}

export default Index