import React from 'react';
import { Link } from 'react-router-dom';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { styles } from '../../../style';
import { profileImg} from '../../../assets/images';
import {
	RiFacebookBoxLine,
	RiInstagramLine,
	RiLinkedinBoxLine,
	RiTwitterXLine,
} from 'react-icons/ri';

const About = () => {
	return (
		<div className="about-me">
			<div className="about-me-info">
				<div className="about-me-info-heading1">
					<p>
						Hello!{' '}
						<span className="text-colorPrimary font-normal">I'm Dasang</span>
					</p>
				</div>
				<div className="about-me-info-heading2">
					<div className="about-me-info-heading2-static-text">
						<p>I'm a</p>
					</div>
					<div className="about-me-info-heading2-dynamic-text">
						<p>content&nbsp;writer</p>
					</div>
				</div>
				<div className={`about-me-info-description ${styles.paragraph1}`}>
					<p>
						I love writing contents. I've been blogging for past 5 years on
						different categories. Entertaining, Awarness and Educating people
						through digital platform has always been my priority. I have been
						putting on lots of effort into research to present incredible
						contents for my readers.
					</p>
				</div>
			</div>
			<div className="about-me-social-links-icons">
				<a href="https://www.facebook.com" target="_blank" rel="noreferrer">
					<RiFacebookBoxLine className="about-me-social-links-icon" />
				</a>
				<a href="https://www.instagram.com" target="_blank" rel="noreferrer">
					<RiInstagramLine className="about-me-social-links-icon" />
				</a>
				<a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
					<RiLinkedinBoxLine className="about-me-social-links-icon" />
				</a>
				<a href="https://www.twitter.com" target="_blank" rel="noreferrer">
					<RiTwitterXLine className="about-me-social-links-icon" />
				</a>
			</div>
			<div className="about-me-img">
				<div className="about-me-img-profile-container">
					<img className="about-me-img-profile" src={profileImg} alt="" />
				</div>
			</div>
			<div className="home-link">
				<Link to="/">
					<RiArrowGoBackFill className="mr-1" />
					<p>home</p>
				</Link>
			</div>
		</div>
	);
};

export default About;
