import React from 'react';
import { HiOutlineRss } from 'react-icons/hi2';
import { styles } from '../../style';
import { playBtn} from '../../assets/images';

const Hero = ({ category }) => {
	return (
		<div className={`hero ${styles.sectionPaddingX} ${styles.sectionPaddingY}`} style={{backgroundImage: `url(${category.image})`}}>
			<div className="hero-content">
				<div className="hero-heading">
					<div className="hero-heading-title">
					<img className="hero-heading-img" src={playBtn} alt="" />
						{category.title}{' '}
					</div>
					<p className={`hero-heading-description ${styles.paragraph3}`}>
						{category.description}
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit 
						quis repudiandae temporibus, repellat commodi neque ratione architecto 
					</p>
					<div className="hero-btn-container">
						<a href="#hero-btn-target" className="hero-btn">
							explore blogs <HiOutlineRss className="ml-1 text-[110%]" />
						</a>
					</div>
				</div>
			</div>
			<span id="hero-btn-target" className="hero-btn-target">
				hero btn target
			</span>
		</div>
	);
};

export default Hero;
