import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { HiMiniSquare3Stack3D } from "react-icons/hi2";
import {styles} from '../../../style'
import { TableSearch, TableAdd, TableCount, TableRowLimit, Table, Pagination, CategoryPreview } from '../../../components/dashboard';
import { categories } from '../../../constants';

const Index = () => {
	//Define navigate for routing
	const navigate = useNavigate();

  //Define the number of rows for table to be displayed
	const[rows, setRows] = useState(10);

  //Define the number of table rows to be selected from dropdown
	const rowOptions = [10, 20, 30, 40, 50];

	//Define the search query string for table data
	const[searchQuery, setSearchQuery] = useState('');

  //Define current page & no of pages for pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(5);

	//Define categroy preview status
	const [isCategoryView, setIsCategoryView] = useState(false);
	const [currentCategoryView, setCurrentCategoryView] = useState('');

  //Define table heads
	const tableColumn = [
		{
			id: 1,
			title: 'id',
			filter: false,
		},
		{
			id: 2,
			title: 'title',
			filter: false,
		},
		{
			id:3,
			title: 'description',
			filter: false,
		},
		{
			id:4,
			title: 'date',
			filter: false,
		},
		{
			id:5,
			title: 'actions',
			filter: false,
		},
  ];

  //Handel Category view
  const handleCategoryView = () => {
	setIsCategoryView(false);
  }

  //Event Handlers for view, edit and delete
  const handleOnViewClick = (category) => {
    setIsCategoryView(true);
	setCurrentCategoryView(category);
  }

  const handleOnEditClick = (id) => {
    navigate(`/dashboard/categories/edit/${id}`);
  }

  const handleOnDeleteClick = (id) => {
    alert('id ' + id + ' requested for delete');
  }
 
  return (
		<div className='dashboard-main'>
			<div className="dashboard-main-wrapper">
				<div className={`dashboard-main-heading ${styles.heading4}`}>
					<HiMiniSquare3Stack3D className='dashboard-main-heading-icon'/> manage categories
				</div>
				<div className="dashboard-main-content">
					<div className="dashboard-main-content-header">
						<div className="dashboard-main-content-header-left">
							<TableSearch setSearchQuery={setSearchQuery} searchTag="title"/>
							<TableAdd link='/dashboard/categories/create'/>
						</div>
						<div className="dashboard-main-content-header-right">
							<TableCount count={categories.length} name="categories"/>
							<TableRowLimit rows={rows} setRows={setRows} rowOptions={rowOptions}/>
						</div>
					</div>
					<div className="dashboard-main-content-data">
						<Table tableColumn={tableColumn} tableData={categories} tableDataType='category' handleOnViewClick={handleOnViewClick} handleOnEditClick={handleOnEditClick} handleOnDeleteClick={handleOnDeleteClick}/>
						<Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage}/>
					</div>
				</div>
				{isCategoryView &&  <CategoryPreview category={currentCategoryView} imagePreview={currentCategoryView.image} handleCategoryPreview={handleCategoryView} actions={true} handleOnEditClick={handleOnEditClick} handleOnDeleteClick={handleOnDeleteClick}/>}
			</div>
		</div>
  )
}
export default Index;