import React from 'react'
import { styles } from '../../../style';
import { RiShieldUserFill } from "react-icons/ri";

const Index = () => {
  return (
    <div className='dashboard-main'>
     <div className="dashboard-privacy-policy-wrapper">
        <div className={`dashboard-privacy-policy-heading ${styles.heading4}`}>
          <RiShieldUserFill className='dashboard-privacy-policy-heading-icon'/> manage privacy policy
        </div>
        <div className="dashboard-privacy-policy-content">
          This is privacy policy management content
        </div>
      </div>
    </div>
    
  )
}

export default Index