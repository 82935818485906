import { useState, useEffect } from 'react';

const StickyNavStatus = () => {
	const [stickyNav, setStickyNav] = useState(false);

	useEffect(() => {
		// Define a callback function
		const handleScroll = () => {
			const scrollPosition = window.scrollY;

			//Check if page is scrolled down
			if (scrollPosition > 0) {
				setStickyNav(true);
			} else {
				setStickyNav(false);
			}
		};

		//Add callback function as a listener
		window.addEventListener('scroll', handleScroll);

		//Remove event listener when the component is unmounted
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [stickyNav]);
	return stickyNav;
};

export default StickyNavStatus;
