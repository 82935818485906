import React from 'react';
import { Link } from 'react-router-dom';
import { HiFire, HiOutlineCalendarDays } from 'react-icons/hi2';
import { avatar} from '../../assets/images';
import { styles } from '../../style';
import { blogs } from '../../constants';
import {Star} from '../../components';

const Featured = () => {
	const featuredBlog = blogs.find((blog) => blog.id === '7');

	return (
		<div className={`${styles.sectionPaddingX} ${styles.heroSectionPaddingY}`}>
			<div className={`${styles.sectionHeading} featured-blog-heading`}>
				<HiFire className="text-colorSecondary mr-1" /> Featured Blog
			</div>
			<div className={`featured-blog ${styles.paddingY}`}>
				<div className="featured-blog-img">
					<div className="img-placeholder" style={{backgroundImage: `url(${featuredBlog.image_placeholder})`}}>
						<img className="img-original" src={featuredBlog.image} alt="" loading="lazy"/>
					</div>
				</div>
				<div className={`featured-blog-content ${styles.paddingX}`}>
					<div className={`featured-blog-content-date ${styles.heading3}`}>
						<HiOutlineCalendarDays className="mr-2 " /> {featuredBlog.date}
					</div>
					<h1 className={`featured-blog-content-title ${styles.heading2} `}>
						{featuredBlog.title}
					</h1>
					<div className={`featured-blog-content-author`}>
						<img src={avatar} alt="" className="avatar" />
						<span>Dasangsherlaam</span>
					</div>
					<div className="featured-blog-content-ratings">
						<Star ratings={featuredBlog.ratings}/>
						<span>
							({featuredBlog.ratings}/5)
						</span>
					</div>
					<p className={`featured-blog-content-text ${styles.paragraph1}`}>
						{featuredBlog.description.substring(0, 200)}...
					</p>
					<div className="featured-blog-content-link">
						<Link
							className="btn-primary"
							to={`/${featuredBlog.category}/${featuredBlog.slug}`}
						>
							read more
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Featured;
