import React from 'react';

const FormInput = (props) => {

	return (
		<>
			{(() => {
				switch (props.tag) {
					case 'input':
						return (
							<div className="form-input-container">
								<input
									className="form-input"
									type={props.type}
									id={props.name}
									name={props.name}
									value={props.value}
									placeholder={props.placeholder}
									label={props.label}
									onChange={props.onChange}
									required={props.required}
								/>
								{props.children}
							</div>
						);
					case 'textarea':
						return (
							<div className="form-input-container">
								<textarea
									className="form-input"
									rows={props.rows}
									id={props.name}
									name={props.name}
									value={props.value}
									placeholder={props.placeholder}
									onChange={props.onChange}
									required={props.required}
								/>
								{props.children}
							</div>
						);
					case 'file' : 
					return (
						<div className="form-input-container">
							<div>
								<input className='form-input form-input-file'
								 id={props.name} 
								 type="file" 
								 accept="image/jpg, image/jpeg, image/png"
								 name={props.name} 
								 ref={props.refProp}
								 onChange={props.onChange}
								 required={props.required}
								/>
							</div>
						</div>
					)
					default:
						return null;
					}
			})()}
		</>
	);
};

export default FormInput;
