import React, {useState ,useRef, useEffect} from 'react'
import { HiChevronDown } from "react-icons/hi2";

const TableRowLimit = (props) => {
  const [isDropdown, setIsDropdown] = useState(false);

  const dropdown = useRef();

  useEffect(() => {
    //Close table row limit dropdown on outside the element click
    const handleRowLimitOutsideClick = (e) => {
      if(dropdown && !dropdown.current.contains(e.target)) {
        setIsDropdown(false);
      }
    }
    //Add event listener 
    document.addEventListener('click', handleRowLimitOutsideClick);

    //Remove event listener
    return () => {
      document.removeEventListener('click', handleRowLimitOutsideClick);
    }

  },[]);

  return (
    <div className="table-row-limit-wrapper">
        <div className="table-row-limit-btn" onClick={() => setIsDropdown(!isDropdown)} ref={dropdown}>
          <span>page size: {props.rows}</span>
          <span><HiChevronDown className={isDropdown ? '-rotate-90 duration-300' : 'rotate-0 duration-300'}/></span>
        </div>
        <div className={isDropdown ? 'table-row-limit-dropdown' : 'table-row-limit-dropdown table-row-limit-dropdown-hidden'}>
          <ul className='table-row-limit-dropdown-list'>
            {
              props.rowOptions.map((option) => (
                <li className='table-row-limit-dropdown-list-item' key={option} onClick={() => {props.setRows(option); setIsDropdown(!isDropdown);}}>{option}</li>
              ))
            }
          </ul>
        </div>
    </div>
  )
}

export default TableRowLimit