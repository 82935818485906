import React from 'react';
import { HiMiniChartBarSquare } from "react-icons/hi2";
import { styles } from '../../../style';

const Index = () => {
	return <div className="dashboard-main">
				<div className="dashboard-home-wrapper">
					<div className={`dashboard-home-heading ${styles.heading4}`}>
						<HiMiniChartBarSquare className='dashboard-home-heading-icon'/>data & insights
					</div>
					<div className="dashboard-home-content">
						This is home management content
					</div>
				</div>
			</div>;
};

export default Index;
