import React from 'react'

const TableFilter = (props) => {
  return (
    <div className={props.filterStatus ? 'table-filter-wrapper table-filter-wrapper-active' : 'table-filter-wrapper'}>
        <div className="table-filter-options">
          {
            props.options.map((option, index) => (
              <div key={index} className='table-filter-options-item' onClick={() => props.getSelectedFilter(props.filterTitle, option)}>
                {option}
              </div>
            ))
          }
        </div>
    </div>
  )
}

export default TableFilter