import React from 'react'
import { Link } from 'react-router-dom';
import { HiMiniPlus } from "react-icons/hi2";

const TableAdd = (props) => {
  return (
    <div className='table-btn-add-wrapper'>
      <Link to={props.link} className='table-btn-add'>add new <HiMiniPlus className='text-[20px]'/> </Link>
    </div>
  )
}

export default TableAdd