import React, {useState} from 'react'
import { HiOutlineHandThumbUp } from 'react-icons/hi2'
import { comments } from '../../constants'
import {FormInput, CommentItem} from '..'

const Comment = () => {

const [comment, setComment] = useState('');

const handleOnChangeComment = (e) => {
    setComment(e.target.value);
}

const handleOnSubmitComment = (e) => {
    e.preventDefault()
    // API Call here
}

  return (
    <div className="blog-comment">
        <div className="blog-comment-heading">
            <div className="blog-comment-heading-left">
                <div className="blog-comment-title-1">comments</div>
                <a href="#comment-section" className="blog-comment-title-2">
                    write comment
                </a>
            </div>
            <div className="blog-comment-count"><span>(54)</span> comments</div>
        </div>
        <div className="blog-comment-body">
            {
                comments.length ? ( <div className="blog-comment-list">
                    <span className='blog-comment-list-title'>top 5 comments</span>
                        {
                            comments.filter((comment) => comment.parent_id === '').map(comment => (
                                <CommentItem key={comment.id} comment={comment} />
                            ))
                        }
                        <div className="blog-comment-list-footer">
                            <button>load more..</button>
                        </div> 
                        <span id="comment-section"></span>
                </div>) : (
                    <div className='blog-comment-empty'>
                       <span className="blog-comment-empty-title-1"># no comments yet</span>
                       <span className="blog-comment-empty-title-2"><HiOutlineHandThumbUp/> be the first to comment</span>
                    </div>
                )
            }
            <div className="blog-comment-post">
                <div className="blog-comment-post-title">
                    <p>what's your say ?</p>
                    <span></span>
                </div>
                <form onSubmit={handleOnSubmitComment}>
                    <FormInput 
                    tag="textarea" 
                    id="comment" 
                    name="comment"
                    rows="8" 
                    onChange={handleOnChangeComment}
                    >
                        <div className="blog-comment-btn">
                            <button type="submit" className='blog-comment-btn-submit' disabled={comment ? false : true}>comment</button>
                        </div>
                    </FormInput>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Comment