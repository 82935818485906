import React from 'react';
import { Link } from 'react-router-dom';
import { HiChevronDoubleRight } from 'react-icons/hi2';
import { styles } from '../../style';
import Card from '../card/Card';

const Category = ({ blogs, categoryTitle, categorySlug, page }) => {
	const categoryBlogs = blogs.filter((blog) => blog.category === categoryTitle);

	return (
		<div
			className={`catergory-blogs ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}
		>
			<div className={`category-blogs-heading ${styles.sectionHeading}`}>
				<div className="category-blogs-heading-text">
					{page === 'single' ? 'related blogs' : categoryTitle}
				</div>
				<Link to={`/categories/${categorySlug}`} className="category-blogs-link">
					view more posts <HiChevronDoubleRight className="ml-1" />
				</Link>
			</div>
			<div className={`category-blogs-content ${styles.paddingY}`}>
				{(page === 'single' ? blogs : categoryBlogs).slice(0, 4).map((blog) => (
					<Card key={blog.id} blog={blog} />
				))}
			</div>
		</div>
	);
};

export default Category;
