import React from 'react'
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton} from 'react-share';
import {
	RiFacebookBoxLine,
	RiLinkedinBoxLine,
	RiTwitterXLine,
} from 'react-icons/ri';


const Share = (props) => {
  return (
        <div className='blog-share'>
            <div className="blog-share-title">Share</div>
                <div className="blog-share-icon-list">
                    <FacebookShareButton url={props.url} title={props.title}>
                        <RiFacebookBoxLine className='blog-share-icon'/>
                    </FacebookShareButton>
                    <TwitterShareButton url={props.url} title={props.title}>
                        <RiTwitterXLine className='blog-share-icon'/>
                    </TwitterShareButton>
                    <LinkedinShareButton url={props.url} title={props.title}>
                        <RiLinkedinBoxLine className='blog-share-icon'/>
                    </LinkedinShareButton>
                </div>
        </div>
  )
}

export default Share