import React from 'react'
import { useState, useEffect, useRef} from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';

const CustomSelect = ({options, errorIcon, errorStatus, category, setCategory }) => {
    const [dropdown, setDropdown] = useState(false);

    const selectBtn = useRef();

    useEffect(() => {
        
        //Close select dropdown on outside select btn click
        const handleSelectOutsideClick = (e) => {
            if(selectBtn && !selectBtn.current.contains(e.target)) {
                setDropdown(false);
            }
        }

        //Add event listener 
        document.addEventListener('click', handleSelectOutsideClick);

        //Remove event listener
        return () => {
            document.removeEventListener('click', handleSelectOutsideClick);
        }

        //eslint-disable-next-line
    }, []);

  return (
    <div className='custom-select form-input-container'>
        <div ref={selectBtn} className="custom-select-selected form-input" onClick={() => setDropdown((prev) => !prev)}>
            {category !== '' ? category : 'Select a category'}
            <RiArrowDownSLine className={dropdown ? 'custom-select-dropdown-icon-active' : 'custom-select-dropdown-icon'}/>
            <div className={dropdown ? 'custom-select-dropdown-options form-input' : 'custom-select-dropdown-options custom-select-dropdown-options-hidden form-input'}>
                {options.map(option => (
                    <span key={option.id} 
                    onClick={() => setCategory(option.title)}
                    >
                        {option.title}</span>
                ))}
            </div>
        </div>
        {
            errorStatus && category.length === 0 ?   <div className="input-error">
            {errorIcon}
            <p className='input-error-text'>Please select a category</p>
		    </div> : ''
            }
    </div>
        
  )
}

export default CustomSelect