import React from 'react';

const SearchNotFound = ({ keyword }) => {
	return (
		<div className="search-not-found">
			<div className="search-not-found-heading">
				(<span>0</span>) result
			</div>
			<div className="search-not-found-text">
				Sorry! result could not be found for "{keyword}".
			</div>
		</div>
	);
};

export default SearchNotFound;
