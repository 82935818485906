import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { RxDashboard, RxHamburgerMenu, RxCross2 } from 'react-icons/rx';
import { dashboardLinks } from '../../../constants';

const Sidebar = () => {
	const location = useLocation();

	const currentPath = location.pathname;

	const [sidebarHeight, setSidebarHeight] = useState('');

    const [sidebarBrandHeight, setSidebarBrandHeight] = useState('');

	const [toggelSidebar, setToggleSidebar] = useState(false);

	 //Method to get the height of the sidebar brand
	 const getHeight = useCallback(() => {
	// Get the height of the sidebar
	const sidebar = document.querySelector('.dashboard-sidebar');
	setSidebarHeight(sidebar.offsetHeight);

	// Get the height of the sidebar brand 
	const sidebarBrand = document.querySelector('.dashboard-sidebar-brand');
	setSidebarBrandHeight(sidebarBrand.offsetHeight);

	const sidebarList = document.querySelector('.dashboard-sidebar-list-container');

	//Set the height of the sidebar list
	sidebarList.style.height = sidebarHeight - sidebarBrandHeight + 'px';

	}, [sidebarBrandHeight, sidebarHeight]);

	useEffect(() => {
		getHeight();
	}, [getHeight])

	return (
		<div
			className={
				toggelSidebar
					? 'dashboard-sidebar dashboard-sidebar-sm-active'
					: 'dashboard-sidebar'
			}
		>
			<div className="dashboard-sidebar-brand">
				<div className="dashboard-sidebar-brand-title">
					welcome to dasangblogs!
				</div>
				<a href="/dashboard">
					<RxDashboard /> dashboard
				</a>
			</div>
			<div className='dashboard-sidebar-list-container'>
				{dashboardLinks.map((dashboardLink) => (
					<a
						key={dashboardLink.id}
						href={dashboardLink.path}
						className={
							currentPath === dashboardLink.path
								? 'dashboard-sidebar-list-active'
								: 'dashboard-sidebar-list'
						}
					>
						<div className="dashboard-sidebar-list-item">
							<div className="dashboard-sidebar-list-item-icon">
								{dashboardLink.icon}
							</div>
							<div className="dashbboard-sidebar-list-item-text">
								{dashboardLink.title}
							</div>
						</div>
					</a>
				))}
			</div>
			<div className="dashboard-sidebar-toggle">
				<RxHamburgerMenu
					className={toggelSidebar ? 'hidden' : 'block text-colorSecondary'}
					onClick={() => setToggleSidebar(!toggelSidebar)}
				/>
				<RxCross2
					className={toggelSidebar ? 'block text-colorTextWhite' : 'hidden'}
					onClick={() => setToggleSidebar(!toggelSidebar)}
				/>
			</div>
		</div>
	);
};

export default Sidebar;
