import React, {useState} from 'react';
import { HiOutlineUser, HiOutlineEnvelope } from 'react-icons/hi2';
import { RiKeyboardLine } from 'react-icons/ri';
import { styles } from '../../../style';
import { FormInput, Newsletter } from '../../../components';
import { squareDown, squareUp } from '../../../assets/images';

const Contact = () => {
	const [values, setValues] = useState({
		name: '',
		email: '',
		message: ''
});

const handleOnChange =(e) => {
	setValues({...values, [e.target.name]: e.target.value});
}

const handleSubmit = (e) => {
	e.preventDefault();
	// API call here
}
 
	return (
		<>
			<div className={`contact ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}>
				<div className="contact-text">
					<div className="contact-text-heading">Have any queires ?</div>
					<div className="contact-text-subheading">
						Please feel free to send in a message..
					</div>
				</div>
				<div className="contact-form-container">
					<form onSubmit={handleSubmit} className="contact-form form-shadow">
						<FormInput
							tag="input"
							type="text"
							name="name"
							value={values.name}
							placeholder="What's your name?"
							onChange={handleOnChange}
							required={true}
						>
							<HiOutlineUser className="form-input-icon" />
						</FormInput>
						<FormInput
							tag="input"
							type="email"
							name="email"
							value={values.email}
							placeholder="What's your email?"
							onChange={handleOnChange}
							required={true}
						>
							<HiOutlineEnvelope className="form-input-icon" />
						</FormInput>
						<FormInput
							tag="textarea"
							name="message"
							value={values.message}
							placeholder="What do you want to say?"
							onChange={handleOnChange}
							rows="4"
							required={true}
						>
							<RiKeyboardLine className="form-input-icon-start" />
						</FormInput>
						<span className="contact-submit-container">
							<button className="btn-submit" type="submit">
								send
							</button>
						</span>
					</form>
				</div>
				<img className="contact-form-bg-img1" src={squareUp} alt="" />
				<img className="contact-form-bg-img2" src={squareDown} alt="" />
			</div>
			<Newsletter/>
		</>
	);
};

export default Contact;
