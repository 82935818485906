import React from 'react';
import { RouterProvider } from 'react-router-dom';
import MainRoutes from './routes/MainRoutes';

function App() {
	return (
		<div className="main">
			<RouterProvider router={MainRoutes} />
		</div>
	);
}

export default App;
 