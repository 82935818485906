import React from 'react'
import { HiOutlineCheckCircle, HiOutlineExclamationCircle, HiOutlineXCircle } from 'react-icons/hi2'
import {RiLoader3Line} from 'react-icons/ri';

const Toast = ({type, message}) => {

  return (
    <div className="toast-container">
        <div className="toast">
          {
            type === 'success' ? <HiOutlineCheckCircle className='toast-icon toast-icon-success'/> 
            : type === 'failure' ? <HiOutlineXCircle className='toast-icon toast-icon-failure'/> 
            : type === 'warning' ? <HiOutlineExclamationCircle className='toast-icon toast-icon-warning'/>
            : <RiLoader3Line className='toast-icon toast-icon-loader'/>
          }
            {message}
        </div>
    </div>
  )
}

export default Toast