import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Sidebar } from '../components/dashboard';

const DashboardLayout = () => {
	return (
		<div className="dashboard-container">
			<Header />
			<Sidebar />
			<Outlet/>
		</div>
	);
};

export default DashboardLayout;
