export const styles = {
	heading1:
		'text-[22px] lg:text-[28px] xl:text-[34px] tracking-wide font-extrabold capitalize',
	heading2:
		'text-[24px] md:text-[24px] lg:text-[34px] xl:text-[44px] xxl:text-[46px] xxxl:text-[50px] tracking-wide leading-tight lg:leading-tight font-extrabold capitalize mt-3 md:mt-2 lg:mt-3 xl:mt-4',
	heading3:
		'text-[17px] md:text-[16px] lg:text-[18px] xl:text-[16px] xxl:text-[22px] tracking-wide leading-normal font-normal capitalize mt-4 md:mt-0',
	heading4:
		'text-[22px] md:text-[18px] xl:text-[22px] tracking-wide leading-tight mb-1 font-bold capitalize',
	heading5:
		'text-[20px] md:text-[14px] lg:text-[15px] xl:text-[18px] tracking-wide leading-6 md:leading-tight font-semibold capitalize mb-2 mt-1 font-sans',
	paragraph1:
		'text-[17px] lg:text-[18px] xl:text-[20px] xxl:text-[22px] font-light tracking-normal leading-[26px] md:leading-[26px] xl:leading-[34px] my-3 md:my-2 lg:my-3 xl:my-4 xxl:my-6',
	paragraph2:
		'text-[17px] lg:text-[17px] xl:text-[20px] font-light tracking-normal leading-[30px] md:leading-8 xl:leading-9  my-2 xxl:my-3',
	paragraph3:
		'text-[17px] lg:text-[17px] xl:text-[19px] xxl:text-[20px] font-light tracking-normal leading-[22px] md:leading-[22px] xl:leading-[26px] my-2 xxl:my-4',
	paddingX: 'px-0 md:px-12',
	paddingY: 'py-2 lg:py-4 xl:py-6 ',
	heroSectionPaddingY: 'py-4 md:py-8 lg:py-12 xxl:py-14',
	sectionPaddingX: 'px-4 md:px-12',
	sectionPaddingY: 'pt-4 lg:py-6 xl:pt-8 xxl:pt-14',
	sectionHeading:
		'text-[24px] lg:text-[24px] xl:text-[30px] xxxl:text-[34px] tracking-wide font-extrabold uppercase py-2 lg:py-3 font-sans',
};
