import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RiRocketLine } from 'react-icons/ri';

const ScrollToTop = (props) => {
	const location = useLocation();
	const [scrollTop, setScrollTop] = useState(false);
	const [displayScrollBtn, setdispalyScrollBtn] = useState(false);

	const handleScroll = () => {
		//Get scroll Y positoion
		const scrollPosition = window.scrollY;
		if (scrollPosition > 1) {
			setdispalyScrollBtn(true);
		} else {
			setdispalyScrollBtn(false);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);

		//Set scroll top to false after scrolling to top
		setScrollTop(false);

		//Scroll event callback function
		handleScroll();

		//Add scroll event listener
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [location, scrollTop]);

	return (
		<>
			{props.children}
			<div
				className={
					displayScrollBtn
						? 'scroll-top-container'
						: 'scroll-top-container-hidden'
				}
			>
				<div className="scroll-top" onClick={() => setScrollTop(true)}>
					<div className="scroll-top-icon">
						<RiRocketLine />
					</div>
				</div>
			</div>
		</>
	);
};

export default ScrollToTop;
