import React from 'react';
import { styles } from '../../../style';
import { Link } from 'react-router-dom';
import { HiOutlineCalendarDays } from 'react-icons/hi2';
import { blogs, categories } from '../../../constants';
import { Category, Featured, Newsletter, Star } from '../../../components';

function Home() {
	return (
		<div className="home-container">
			<Featured />
			{/* Recent blogs section */}
			<div
				className={`recent-blogs-section ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}
			>
				<div className={`${styles.sectionHeading} recent-blogs-heading`}>
					<div className="recent-blogs-heading-text">recent Blogs</div>
				</div>
				<div className={`${styles.paddingY} recent-blogs-content`}>
					<div className="recent-blogs-content-main">
						{blogs.slice(0, 1).map((blog) => (
							<Link
								to={`/${blog.category}/${blog.slug}`}
								key={blog.id}
								className="recent-blog-main-card-link"
							>
								<div className="recent-blog-main-card group" key={blog.id}>
									<div className="recent-blog-main-card-img">
										<div className="recent-blog-main-card-img-container">
											<div className="img-placeholder" style={{backgroundImage: `url(${blog.image_placeholder})`}}>
												<img className="img-original" src={blog.image} alt="" loading="lazy"/>
											</div>
											<div className="recent-blog-main-card-category-tag">
												<div className="recent-blog-main-card-category-tag-border">
													<span className="invisible">category</span>
													<div className="recent-blog-main-card-category-tag-text">
														category
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="recent-blog-main-card-content">
										<div className="recent-blog-main-card-content-date">
											<HiOutlineCalendarDays className="mr-2" />
											{blog.date}
										</div>
										<div className="recent-blog-main-card-content-title">
											{blog.title}
										</div>
										<div className="recent-blog-main-card-content-ratings">
											<Star ratings={blog.ratings} />
											<span>
												({blog.ratings}/5)
											</span>
										</div>
										<p
											className={`recent-blog-main-card-content-description ${styles.paragraph3}`}
										>
											{blog.description.substring(0, 100)}
										</p>
									</div>
								</div>
							</Link>
						))}
					</div>
					<div className="recent-blogs-content-others">
						{blogs
							.slice(0, 5)
							.filter((blog) => blog.id !== '1')
							.map((restBlog) => (
								<Link
									to={`/${restBlog.category}/${restBlog.slug}`}
									key={restBlog.id}
									className="recent-blog-secondary-card-link"
								>
									<div
										className="recent-blog-secondary-card group"
										key={restBlog.id}
									>
										<div className="recent-blog-secondary-card-img">
											<div className="recent-blog-secondary-card-img-container">
											<div className="img-placeholder" style={{backgroundImage: `url(${restBlog.image_placeholder})`}}>
												<img className="img-original" src={restBlog.image} alt="" loading="lazy"/>
											</div>
												<div className="recent-blog-secondary-card-category-tag">
													<div className="recent-blog-secondary-card-category-tag-border">
														<span className="invisible">category</span>
														<div className="recent-blog-secondary-card-category-tag-text">
															category
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="recent-blog-secondary-card-content">
											<div className="recent-blog-secondary-card-content-date">
												<HiOutlineCalendarDays className="mr-2" />
												{restBlog.date}
											</div>
											<div className="recent-blog-secondary-card-content-ratings">
												<Star ratings={restBlog.ratings} />
												<span>
													({restBlog.ratings}/5)
												</span>
											</div>
											<div className="recent-blog-secondary-card-content-title">
												{restBlog.title}
											</div>
											<p
												className={`recent-blog-secondary-card-content-description ${styles.paragraph3}`}
											>
												{restBlog.description.substring(0, 100)}...
											</p>
										</div>
									</div>
								</Link>
							))}
					</div>
				</div>
			</div>
			{/* Category blogs Section */}
			{categories.map((category) => (
				<Category
					key={category.id}
					categorySlug={category.slug}
					categoryTitle={category.title}
					blogs={blogs}
				/>
			))}
			<Newsletter/>
		</div>
	);
}

export default Home;
