import React from 'react'
import { RiRefreshLine } from "react-icons/ri";

const FilterReset = (props) => {
  return (
    <div className='filter-reset-wrapper'>
        <button className={props.isResetFilter ? 'filter-reset-btn' : 'filter-reset-btn filter-reset-btn-hidden'} onClick={props.onClickResetFilter}>reset filter<RiRefreshLine className='icon text-[18px]'/></button>
  </div>
  )
}

export default FilterReset