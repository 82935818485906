import React from 'react'
import { Tooltip } from '../../../components';
import {TableFilter} from '../index';
import { HiOutlineEye,HiOutlinePencilSquare, HiOutlineTrash } from "react-icons/hi2";

const Table = (props) => {
  return (
    <div className="table-wrapper">
        <table>
            <thead>
                <tr>
                    {
                        props.tableColumn.map((col) => (
                            <th scope="col" key={col.id}>
                                <span className={`table-title-wrapper ${col.filter ? '' : 'pointer-events-none'}`} onClick={() => col.setFilterStatus((prev) => !prev)} ref={col.titleRef} >
                                    <span>{col.title}</span> 
                                    {
                                        col.filter && (		
                                            
                                            <span className={col.filterStatus ? 'table-filter-icon table-filter-icon-active' : 'table-filter-icon'}>{col.icon}</span>
                                                                                                                                                                                                                                                                                
                                        )
                                    }
                                    {
                                        col.filter && (
                                            <TableFilter filterTitle={col.title} filterStatus={col.filterStatus} options={col.filterOptions} getSelectedFilter={props.getSelectedFilter}/>
                                        )
                                    }	
                                </span>
                            </th>
                        ))
                    }
                </tr>
            </thead>
            <tbody>
                {
                    props.tableData.map((data) => (
                        <tr key={data.id}>
                            {
                                props.tableDataType === 'category' &&
                                    <>
                                        <td>{data.id}</td>
                                        <td>{data.title}</td>
                                        <td>{data.description}</td>
                                        <td>{data.date}</td>
                                    </>
                            }
                            {
                                props.tableDataType === 'blogs' && 
                                    <>
                                        <td>{data.id}</td>
                                        <td>{data.title}</td>
                                        <td>{data.date}</td>
                                        <td>{data.category}</td>
                                        <td>{data.ratings}</td>
                                        <td>22</td>
                                    </>
                            }
                            {
                                props.tableDataType === 'users' && 
                                    <>
                                        <td>{data.id}</td>
                                        <td className="flex justify-center"><img className="w-[30px] h-[30px] rounded-full" src={data.image} alt="" /></td>
                                        <td>{data.name}</td>
                                        <td className="lowercase">{data.email}</td>
                                        <td className={data.role === 'publisher' ? 'text-colorSecondary font-medium': ''}>{data.role}</td>
                                        <td>{data.social}</td>
                                        <td className={data.subscription ? 'text-colorSecondary' : 'text-colorDanger'}>{data.subscription ? 'yes' : 'no'}</td>
                                    </>
                            }
                            <td>
                                <span className="table-btns">
                                    <span className="table-btn-view group" onClick={() => props.handleOnViewClick(data)}><Tooltip text='view'/><HiOutlineEye/></span>
                                    {
                                        props.tableDataType !== 'users' && 
                                        <span className="table-btn-edit group" onClick={() => props.handleOnEditClick(data.id)}><Tooltip text='edit'/><HiOutlinePencilSquare/></span>
                                    }
                                    <span className="table-btn-delete group" onClick={() => props.handleOnDeleteClick(data.id)}><Tooltip text='delete'/><HiOutlineTrash/></span>
                                </span>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </div>
  )
}

export default Table