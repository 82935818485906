import React from 'react'

const Tooltip = (props) => {
  return (
    <div className='tooltip-wrapper'>
        <div className='tooltip'>
        {props.text}
        </div>
        <span className="tooltip-arrow"></span>
    </div>
   
  )
}

export default Tooltip