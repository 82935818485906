import React, {useState} from 'react'
import { HiStar, HiXMark} from 'react-icons/hi2';
import {RiLoader3Line} from 'react-icons/ri';

const Rating = ({rating, setRating, toggleRating ,setToggleRating}) => {
    //Define states
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [currentValue, setCurrentValue] = useState(rating);
    const [hoverValue, setHoverValue] = useState(undefined);
    const stars = Array(5).fill(0);

    const handleClick = value => {
        setCurrentValue(value);
    }

    const handleMouseOver = value => {
        setHoverValue(value);
    }

    const handleMouseLeave = () => {
        setHoverValue(undefined);
    }

    const handleSubmit = () => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);

            setIsSubmitted(true);
            
            setTimeout(() => {
                setToggleRating(false);

                setIsSubmitted(false);

            }, 3000);

            setRating(currentValue);
            //API Call
        },3000);
    }

  return (
    <div className='rating-wrapper'>
        <div className='rating-content'>
            <div className="rating-close-button">
                <button disabled={isLoading ? true : false}>
                    <HiXMark onClick={() => {setToggleRating(!toggleRating); setIsSubmitted(false)}}/>
                </button>
            </div>
            {
                isSubmitted ? <div className="rating-title">
                    Thank you !&nbsp; ratings submitted
                </div> : <div className="rating-title">
                how many stars ?
            </div>
            }
            <div className="rating-stars">
                {
                    isSubmitted || isLoading ? (
                        stars.map((_, index) => {
                            return (
                                <HiStar 
                                key={index}
                                className={(hoverValue || currentValue) >= (index+1) ? 'star-active' : 'star-inactive'}
                                />
                            )
                        })
                    ) : (
                        stars.map((_, index) => {
                            return (
                                <HiStar 
                                key={index}
                                className={(hoverValue || currentValue) >= (index+1) ? 'star-active' : 'star-inactive'}
                                onMouseOver={() => handleMouseOver(index + 1)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleClick(index +1)}
                                />
                            )
                        })
                    )
                    
                }
            </div>
            <div className="rating-submit">
                {
                    isLoading && (<div className='rating-loader'>
                        <RiLoader3Line className='rating-loader-icon'/>
                        <span>submitting...</span>
                    </div>)
                }
                <button className={isSubmitted || isLoading ? 'hidden' : 'rating-btn-submit'} onClick={handleSubmit} disabled={currentValue < 1 || currentValue === rating ? true : false}>submit</button>    
            </div>
        </div>
    </div>
  )
}

export default Rating