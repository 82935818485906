import { createBrowserRouter, Navigate } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import DashboardLayout from '../layouts/DashboardLayout';
import {
	Home,
	Login,
	Blog,
	Contact,
	Category,
	Search,
	AboutMe,
	PrivacyPolicy,
	CookiePolicy,
	NotFound,
} from '../ui/pages';
import {
	DashboardHome,
	AllBlogs,
	CreateBlog,
	AllCategories,
	CreateCategory,
	DashboardProfile,
	DashboardPasswordChange,
	AllUsers,
	DashboardPrivacyPolicy,
	DashboardCookiePolicy
} from '../ui/dashboard';

const MainRoutes = createBrowserRouter([
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{
				path: '/',
				element: <Home />,
			},
			{
				path: '/:category/:title',
				element: <Blog />,
			},
			{
				path: '/categories/:title',
				element: <Category />,
			},
			{
				path: '/search/:keyword',
				element: <Search />,
			},
			{
				path: '/contact',
				element: <Contact />,
			},
			{
				path: '/privacy-policy',
				element: <PrivacyPolicy />,
			},
			{
				path: '/cookie-policy',
				element: <CookiePolicy />,
			},
		],
	},
	{
		path: '/dashboard',
		element: <DashboardLayout />,
		children: [
			{
				path: '/dashboard',
				element: <DashboardHome />,
			},
			//Dashboard blog routes
			{
				path: '/dashboard/blogs',
				element: <AllBlogs />,
			},
			{
				path: '/dashboard/blogs/create',
				element: <CreateBlog />,
			},
			{
				path: '/dashboard/blogs/edit/:id',
				element: <CreateBlog />
			},
			//Dashboard category routes
			{
				path: '/dashboard/categories',
				element: <AllCategories />,
			},
			{
				path: '/dashboard/categories/create',
				element: <CreateCategory/>
			},
			{
				path: '/dashboard/categories/edit/:id',
				element: <CreateCategory/>
			},
			//Dashboard user routes
			{
				path: '/dashboard/users',
				element: <AllUsers/>
			},
			//Dashboard change password routes
			{
				path:'/dashboard/change-password',
				element: <DashboardPasswordChange/>
			},
			//Dashboard profile routes
			{
				path: '/dashboard/my-profile',
				element: <DashboardProfile />,
			},
			//Dashboard privacy policy routes
			{
				path: '/dashboard/privacy-policy',
				element: <DashboardPrivacyPolicy/>
			},
			//Dashboard cookie policy routes
			{
				path: '/dashboard/cookie-policy',
				element: <DashboardCookiePolicy/>
			}
		],
	},
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/about-me',
		element: <AboutMe />,
	},
	{
		path: '/not-found',
		element: <NotFound code="404" />,
	},
	{
		path: '*',
		element: <Navigate to="/not-found" replace />,
	},
]);

export default MainRoutes;
