import React, {useState, useRef, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import { HiMiniPencilSquare } from 'react-icons/hi2';
import {MdError} from 'react-icons/md';
import { styles } from '../../../style';
import { FormInput, Hint, Toast } from '../../../components';
import { CategoryPreview } from '../../../components/dashboard';
import {categories} from '../../../constants';

const Create = () => {
  //Get category id from URL
  const params = useParams();
  const paramId = params.id;

  //Define input values for category
  const [values, setValues] = useState({
    title: '',
    description: '',
    image: ''
  });

  //Define input image reference
  const imgRef = useRef();

  //Define state for file/image preview
  const [filePreview, setFilePreview] = useState('');

  //Define error state
  const [error, setError] = useState(false);

  //Define category preview status
  const [categoryPreview, setCategoryPreview] = useState(false);

  //Define loading state on submit
  const [isLoading, setIsLoading] = useState(false);

  const [response, setResponse] = useState(false);

  useEffect(() => {
    if(paramId) {
        const category = categories.find((category) => {
            return category.id == paramId;
        })

        setValues({
            title: category.title,
            description: category.description,
            image: category.image
        });

        setFilePreview(category.image);
    }
  },[paramId]);

  //Handle category preview on click
  const handleCategoryPreview = () => {
    if(values.title !== '' && values.description !== '' & values.image !== '') {
        setCategoryPreview(!categoryPreview);
    }
    else {
        setError(true);
    }
  }

  //Handle on input change
  const handleOnChange = (e) => {
      if(e.target.name === 'image') {
        const file = new FileReader();

        file.onload = function() {
          setFilePreview(file.result);
        }

        file.readAsDataURL(e.target.files[0]);

        setValues({...values, [e.target.name]: e.target.files[0]})
      }
      else {
        setValues({...values, [e.target.name]: e.target.value});
      }
  }

  //Handler for on form submit
  const handleOnSubmit = (e) => {
    e.preventDefault();

    //Form validation
    if(values.title !== '' && values.image !== '' && values.description !== '') {

      //Reset error state
      if(error) {
        setError(false);
      }

      //Create a form data for api payload
      const formData = new FormData();

      formData.append('title', values.title);
      formData.append('image', values.image);
      formData.append('description', values.description);

      //API call here
      setIsLoading(true);

      setTimeout(function() {
          setIsLoading(false);
          setResponse(true);
          setTimeout(function() {
            setResponse(false);
            //If page is create new blog page
            if(!paramId) {
              //Reset input fields
              setValues({
                title: '',
                description: '',
                image: '',
              });
          
              //Reset file input field
              imgRef.current && (imgRef.current.value = '');
              }

          }, 3000);
			}, 3000);
    }
    //Form validation failure
    else {
      setError(true);
    }
   
  }

  return (
    <div className='dashboard-main'>
        <div className="dashboard-category-create-wrapper">
            <div className={`dashboard-category-create-heading ${styles.heading4}`}>
              <HiMiniPencilSquare className='dashboard-category-create-heading-icon'/> {paramId ? 'Edit' : 'Create'} Category
            </div>
            <form onSubmit={handleOnSubmit}>
                <div className="category-title-input-container">
                  <FormInput
                  tag="input" 
                  type="text" 
                  name="title" 
                  value={values.title}
                  id="category_title"
                  placeholder="Enter Category Title" 
                  onChange={handleOnChange} 
                  required=""
                  />
                  </div>
                  {
                    error && values.title === '' ?  <div className="input-error">
                      <MdError/>
                        <p className='input-error-text'>Please add a title</p>
                      </div> : ''
                     }
                <div className="category-image-input-container">
                    <div className="category-image-input">
                        <FormInput
                        tag="file"
                        type="file"
                        name="image"
                        id="image"
                        refProp={imgRef}
                        onChange={handleOnChange}
                        required=""
                        >
                        </FormInput>
                        <div className="category-image-input-preview">
                            {
                              (values.image ? (<img src={filePreview} alt=""/>) : (<p>Image Preview Here</p>))
                            }
                        </div>
                    </div>
                    <Hint text='This image will be used as banner image for category page'/>
                    <div className="category-image-input-error">
                    {
                        error && values.image === '' ? <div className="input-error top-[70%]">
                        <MdError/>
                          <p className='input-error-text'>Please select a file</p>
                        </div> : ''
                    }
                    </div>
                </div>
                <div className="category-description-input-container">
                    <FormInput
                    tag="textarea"
                    name="description"
                    id="description"
                    onChange={handleOnChange}
                    value={values.description}
                    rows="10"
                    placeholder="write more about categroy"
                    required=""
                    />
                </div>
                <Hint text='This content will be used as indroduction for category page'/>
                {
                  error && values.description === '' ? <div className="input-error">
                  <MdError/>
                  <p className='input-error-text'>Please add description</p>
                </div> : ''
                }
                <div className="dashboard-main-buttons">
                    <span className='btn-primary inline' onClick={handleCategoryPreview}>preview</span>
                    <button type="submit" className='btn-primary'>{params.id ? 'edit' : 'publish'}</button>
                </div>
            </form>
            {isLoading && <Toast type="loader" message="please wait" />}
			{!isLoading && response && <Toast type="success" message={params.id ? 'category edited successully' : 'category created successfully'} />}
            {categoryPreview &&  <CategoryPreview category={values} imagePreview={filePreview} handleCategoryPreview={handleCategoryPreview} actions={false}/>}
        </div>
    </div>
  )
}

export default Create