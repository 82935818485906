import React from 'react'
import {HiOutlineXMark} from 'react-icons/hi2';
import { styles } from '../../../style';

const UserPreview = (props) => {

    const handlePreviewClose = () => {
        props.handleUserPreview();
    }

    return (
        <div className="preview-container">
            <div className="preview-close">
                <span onClick={() => handlePreviewClose()}>
                <HiOutlineXMark className="mr-2"/> close 
                </span>
            </div>
            <div className={`preview-content flex min-h-[500px] gap-[20px] justify-center ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}>
                <div className="preview-content-image">
                    <img src={props.user.image} alt="" />
                </div>
                <div className="preview-content-info">
                    <div className="preview-content-info-heading">
                        user information
                    </div>
                    <div className="preview-content-info-details">
                        <div className="preview-content-info-detail">
                            <div className="preview-content-info-detail-title">
                                name 
                            </div>
                            <div className="preview-content-info-detail-description">
                                {props.user.name}
                            </div>
                        </div>
                        <div className="preview-content-info-detail">
                            <div className="preview-content-info-detail-title">
                                email 
                            </div>
                            <div className="preview-content-info-detail-description lowercase">
                                {props.user.email}
                            </div>
                        </div>
                        <div className="preview-content-info-detail">
                            <div className="preview-content-info-detail-title">
                                role 
                            </div>
                            <div className="preview-content-info-detail-description">
                                {props.user.role}
                            </div>
                        </div>
                        <div className="preview-content-info-detail">
                            <div className="preview-content-info-detail-title">
                                social 
                            </div>
                            <div className="preview-content-info-detail-description">
                                {props.user.social}
                            </div>
                        </div>
                        <div className="preview-content-info-detail">
                            <div className="preview-content-info-detail-title">
                                subscription 
                            </div>
                            <div className={props.user.subscription ? 'preview-content-info-detail-description text-colorSecondary' : 'preview-content-info-detail-description text-colorDanger'}>
                                {props.user.subscription ? 'yes' : 'no'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserPreview