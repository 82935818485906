import React from 'react';
import {styles} from '../../../style';
import { HiKey } from "react-icons/hi2";

const Index = () => {
	return <div className='dashboard-main'>
	<div className="dashboard-password-change-wrapper">
	  <div className={`dashboard-password-change-heading ${styles.heading4}`}>
		<HiKey className='dashboard-password-change-heading-icon'/> change password
	  </div>
	  <div className="dashboard-password-change-content">
		This is password change content
	  </div>
	</div>
  </div>
};

export default Index;
