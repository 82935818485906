import React, {useState, useRef, useEffect} from 'react';
import { useParams} from 'react-router-dom';
import { styles } from '../../../style';
import { HiMiniPencilSquare } from "react-icons/hi2";
import {MdError} from 'react-icons/md';
import {FormInput, CustomSelect, Toast, Editor} from '../../../components';
import { categories } from '../../../constants';
import { BlogPreview } from '../../../components/dashboard';
import { blogs } from '../../../constants';

const Create = () => {
	
	//Get blog id from URL
	const params = useParams();
	const paramId = params.id;

	const [values, setValues] = useState({
		title: '',
		image: '',
	});

	const [editorContent, setEditorContent] = useState('');

	const imgRef = useRef();

	const [category, setCategory] = useState('');

	const [filePreview, setFilePreview] = useState('');

	const [blogPreview, setBlogPreview] = useState(false);

	const [error, setError] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const [response, setResponse] = useState(false);

	useEffect(() => {
		
		if(paramId) {
			const blog = blogs.find((blog) => {
				return blog.id == paramId;
			})

			setValues({
				title: blog.title,
				image: blog.image
			});

			setFilePreview(blog.image);
			setCategory(blog.category);
			setEditorContent(blog.description);
		}
	}, [paramId]);

	//Handle on input change
	const handleOnChange = (e) => {
		if(e.target.name === 'image') {
			const file = new FileReader();

			file.onload = function() {
				setFilePreview(file.result);
			}

			file.readAsDataURL(e.target.files[0]);

			setValues({...values, image: e.target.files[0]});
		
		}
		else {
			setValues({...values, [e.target.name]: e.target.value});
		}
		
	}

	//Handle blog preview on click
	const handleBlogPreview = () => {
		if(values.title !== '' && category !== '' && values.image !== '' && editorContent !== '') {
			setBlogPreview(!blogPreview);
		}
		else {
			setError(true);
		}
	}

	// Handle on form submit
	const handleOnSubmit = (e) => {
		e.preventDefault();

		if(values.title !== '' && category !== '' && values.image !== '' && editorContent !== '') {
			
			//Reset error if is true (if form validation was invoked before submission)
			if(error) {
				setError(false);
			}
			
			const formData = new FormData();
			
			formData.append('title', values.title);
			formData.append('category', category);
			formData.append('image', values.image);
			formData.append('description', editorContent);

			//API call here
			setIsLoading(true);

			setTimeout(function() {
				setIsLoading(false);
				setResponse(true);
				setTimeout(function() {
					setResponse(false);
					//If page is create new blog page
					if(!paramId) {
						//Reset input fields
						setValues({
							title: '',
							image: '',
						});
						
						setEditorContent('');
						
						//Reset category select
						setCategory('');

						//Reset file input field
						imgRef.current && (imgRef.current.value = '');
						}

				}, 3000);
			}, 3000);
		}
		else {
			setError(true);
		}
	}
	
	return (
		<div className="dashboard-main">
			<div className="dashboard-blog-create-wrapper">
				<div className={`dashboard-blog-create-heading ${styles.heading4}`}>
					<HiMiniPencilSquare className='dashboard-blog-create-heading-icon'/> {paramId ? 'Edit Blog' : 'Create Blog'}
				</div>
				<form onSubmit={handleOnSubmit}>
					<div className="blog-title-input-container">
						<FormInput 
						tag="input" 
						type="text" 
						name="title" 
						value={values.title}
						id="blog_title"
						placeholder="Enter Blog Title" 
						onChange={handleOnChange} 
						required=""
						/>
						{
							error && values.title === '' ?  <div className="input-error">
							<MdError/>
							<p className='input-error-text'>Please add a title</p>
						</div> : ''
						}
					</div>
					<CustomSelect options={categories}
						errorIcon={<MdError/>}
						errorStatus={error}
						category={category}
						setCategory={setCategory}
						/>
					<div className="blog-image-input-container">
						<div className="blog-image-input">
							<FormInput
									tag="file"
									type="file"
									name="image"
									id="image"
									refProp={imgRef}
									onChange={handleOnChange}
									required=""
								/>
								<div className="blog-image-input-preview">
									{
										(values.image ? (<img src={filePreview} alt=""/>) : (<p>Image Preview Here</p>))
									}
								</div>
						</div>
						<div className="blog-image-input-error">
							{
								error && values.image === '' ? <div className="input-error top-[70%]">
								<MdError/>
								<p className='input-error-text'>Please select a file</p>
							</div> : ''
							}
						</div>
					</div>
					<div className="blog-content-input-container">
						<Editor
						placeholder="Write something amazing here..."
						value={editorContent}
						onChange={setEditorContent}
						/>
						{
							error && editorContent === '' ? <div className="input-error">
							<MdError/>
							<p className='input-error-text'>Please add a content</p>
						</div> : ''
						}
					</div>
					<div className="dashboard-main-buttons">
						<span className='btn-primary inline' onClick={handleBlogPreview}>preview</span>
						<button type="submit" className='btn-primary'>{params.id ? 'edit' : 'publish'}</button>
					</div>
				</form>
				{
					blogPreview ? <BlogPreview handleBlogPreview={handleBlogPreview} title={values.title} category={category} image={filePreview} content={editorContent}/> : ''
				}
				{isLoading && <Toast type="loader" message="please wait" />}
				{!isLoading && response && <Toast type="success" message={params.id ? 'blog edited successully' : 'blog created successfully'} />}
			</div>
		</div>
	)
};

export default Create;
