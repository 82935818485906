import React from 'react'
import {HiOutlineMagnifyingGlass} from 'react-icons/hi2';

const TableSearch = (props) => {
  return (
    <div className='table-search-wrapper'>
      <input className='table-search-input' type="text" onChange={(e) => props.setSearchQuery(e.target.value)} placeholder={`search by ${props.searchTag}`}/>
      <HiOutlineMagnifyingGlass className='table-search-input-icon' />
    </div>
  )
}

export default TableSearch