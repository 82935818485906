import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { HiLockClosed, HiOutlineUser, HiOutlineKey } from 'react-icons/hi2';
import { FormInput } from '../../../components';
import { styles } from '../../../style';

function Login() {
	const [values, setValues] = useState({
		email: '',
		password: ''
	});

	const handleOnChange = (e) => {
		setValues({...values, [e.target.name]: e.target.value});
	}
	console.log(values);

	const handleSubmit = (e) => {
		e.preventDefault();
		// API call here
	}

	return (
		<div className={`login ${styles.sectionPaddingX}`}>
			<form onSubmit={handleSubmit} className="login-form form-shadow">
				<div className="login-title">
					<HiLockClosed className="login-icon" />
					Dasang Login
				</div>
				<FormInput
					tag="input"
					type="email"
					name="email"
					value={values.email}
					placeholder="Email"
					onChange={handleOnChange}
					required={true}
				>
					<HiOutlineUser className="form-input-icon" />
				</FormInput>
				<FormInput
					tag="input"
					type="password"
					name="password"
					value={values.password}
					placeholder="password"
					onChange={handleOnChange}
					required={true}
				>
					<HiOutlineKey className="form-input-icon" />
				</FormInput>
				<div className="login-submit-container">
					<button type="submit" className="btn-submit">
						login
					</button>
				</div>
			</form>
			<div className="login-footer">
				<Link className="login-footer-link" to="/">
					<RiArrowGoBackFill className="mr-1" /> home
				</Link>
			</div>
		</div>
	);
}

export default Login;
