import React, {useEffect} from 'react';
import { Navbar, Footer } from '../components';
import { Outlet, useLocation } from 'react-router-dom';
import ScrollToTop from '../hooks/UseScrollToTop';
import imageLoader from '../js/imageloader';

const MainLayout = () => {

	const location = useLocation();

	useEffect(() => {
		//Image loader function call
		imageLoader();
		
		}, [location]);

	return (
		<ScrollToTop>
			<Navbar />
			<Outlet />
			<Footer />
		</ScrollToTop>
	);
};

export default MainLayout;
