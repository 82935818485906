import React from 'react';
import { RiAccountBoxFill } from "react-icons/ri";
import { styles } from '../../../style';

const Index = () => {
	return <div className="dashboard-main">
				<div className="dashboard-profile-wrapper">
					<div className={`dashboard-profile-heading ${styles.heading4}`}>
						<RiAccountBoxFill className='dashboard-profile-heading-icon'/> manage profile
					</div>
					<div className="dashboard-profile-content">
						This is profile management content
					</div>
				</div>
			</div>;
};

export default Index;
