import { React, useState } from 'react';
import { useParams} from 'react-router-dom';
import { HiOutlineCalendarDays, HiStar } from 'react-icons/hi2';
import { blogs, categories } from '../../../constants';
import { avatar } from '../../../assets/images';
import { styles } from '../../../style';
import { Category, Newsletter, Comment, Share, Rating, Star } from '../../../components';

function Blog() {

	//Get blog category & title from URL
	const params = useParams();
	//Get current url
	const currentURL = window.location.href;
	//Find the current blog to be shown
	const currentBlog = blogs.find((blog) => blog.slug === params.title);

	//Current blog category
	const category = categories.find(
		(category) => category.title === currentBlog.category
	);

	//Initialize rating 
	const [rating, setRating] = useState(0);
	//Initialize toggle rating form
	const [toggleRating, setToggleRating] = useState(false);
	//Define array of stars
	const stars = Array(5).fill();
	
	//Find other blogs expect the current blog
	const relatedBlogs = blogs.filter(
		(blog) => blog.id !== currentBlog.id && blog.category === category.title
	);

	return (
		<>
			<div
				className={`single-blog ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}
			>
				<div className="single-blog-content">
					<div className={`single-blog-heading ${styles.heading2}`}>
						{currentBlog.title}
					</div>
					<div className="single-blog-content-info">
						<div className="single-blog-content-info-text">
							<span className="single-blog-content-info-text-date">
								<HiOutlineCalendarDays className="mr-1" /> {currentBlog.date}{' '}
							</span>
							<span className="font-extrabold mx-3">|</span>{' '}
							<img src={avatar} alt="profile_image" className="avatar" />
							<span>Dasanghserlaam</span>
						</div>
						<div className="single-blog-content-info-ratings">
							<div className="single-blog-ratings-star">
								<Star ratings={currentBlog.ratings} />
								<span>
									({currentBlog.ratings}/5)
								</span>
							</div>
						</div>
					</div>
					<div className="single-blog-main-img">
						<div className="single-blog-main-img-container">
							<div className="img-placeholder" style={{backgroundImage: `url(${currentBlog.image_placeholder})`}}>
								<img className="img-original" src={currentBlog.image} alt="blog_image" loading="lazy" />
							</div>
							<div className="single-blog-category-tag">
								<div className="single-blog-category-tag-border">
									<span className="invisible">{currentBlog.category}</span>
									<div className="single-blog-category-tag-text">
										{currentBlog.category}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className={`single-blog-content-description-container ${styles.paragraph2}`}
					>
						{' '}
						<p className="single-blog-content-description">
							{currentBlog.description}
						</p>
					</div>
					<div className="blog-share-rating">
						<Share url={currentURL} title={currentBlog.title} />
						<div className="blog-rating" onClick={() => setToggleRating(!toggleRating)}> 
							{
								rating > 0  ? <div className="blog-rating-title">your ratings</div>
								: <div className="blog-rating-title">rate blog</div>
							}
							<div className="blog-rating-stars">
								{
									stars.map((_, index) => {
										return (
											<HiStar key={index} className={rating >= (index + 1) ? 'star-active' : 'star-inactive'}/>
										)
									})
								}
							</div>
						</div>
					</div>
					<Comment/>
				</div>
				{
					toggleRating && (<Rating rating={rating} setRating={setRating} toggleRating={toggleRating} setToggleRating={setToggleRating}/>)
				}
			</div>
			<Category
				blogs={relatedBlogs}
				page="single"
				categorySlug={category.slug}
			/>
			<Newsletter/>
		</>
	);
}

export default Blog;