import React from 'react'
import {HiStar} from 'react-icons/hi2';

const Stars = ({ratings}) => {
  const stars = Array(5).fill();
  return (
    <>
      {
        stars.map((_, index) => (
          
            <HiStar key={index} className={ratings >= (index + 1) ? 'star-active cursor-default' : 'star-inactive cursor-default'}/>
        ))
      }
    </>
  )
}

export default Stars