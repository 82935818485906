import React from 'react'
import {HiOutlineXMark} from 'react-icons/hi2';
import { styles } from '../../../style';
import { HiOutlinePencilSquare, HiOutlineTrash } from "react-icons/hi2";

const CategoryPreview = (props) => {

    const handlePreviewClose = () => {
        props.handleCategoryPreview();
    }

    //Handle on edit click
    const handleOnEditClick = () => {
        props.handleOnEditClick(props.category.id);
    }

    //Handle on delete click 
    const handleOnDeleteClick = () => {
        props.handleOnDeleteClick(props.category.id);
    }

  return (
    <div className="preview-container">
        <div className="preview-close">
            <span onClick={() => handlePreviewClose()}>
            <HiOutlineXMark className="mr-2"/> close 
            </span>
        </div>
        <div className={`preview-content ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}>
            <div className="preview-content-category-image">
                <div className="preview-content-category-image-title">
                    <span>
                        background image
                    </span>
                    {
                      props.actions && 
                        <div className='preview-content-category-action'>
                          <button className='preview-content-category-edit' onClick={handleOnEditClick}><HiOutlinePencilSquare/> edit</button>
                          <button className='preview-content-category-delete' onClick={handleOnDeleteClick}><HiOutlineTrash/> delete</button>
                        </div>
                    }
                    
                </div>
                <img src={props.imagePreview} alt="" />
            </div>
          <div className="preview-content-category-details-wrapper">
              <div className="preview-content-category-details-heading">
                category details
              </div>
              <div className="preview-content-category-detail">
                  <div className="preview-content-category-detail-left">
                    <span> title</span>
                  </div>
                  <div className="preview-content-category-detail-right">
                    {props.category.title}
                  </div>
              </div>
              <div className="preview-content-category-detail">
                  <div className="preview-content-category-detail-left">
                    <span>
                        description
                    </span>
                  </div>
                  <div className="preview-content-category-detail-right">
                    {props.category.description}
                  </div>
              </div>
          </div>
        </div>
    </div>
  )
}

export default CategoryPreview