const imageLoader = () => {
    const blurredImageDiv = document.querySelectorAll('.img-placeholder');

		blurredImageDiv.forEach(div => {
			const img = document.querySelector('.img-original');

			function loaded() {
				div.classList.add('loaded');
			}
	
			if(img.complete) {
				loaded();
			}
			else {
				img.addEventListener('load', loaded);
			}
		});
}

export default imageLoader;