import React from 'react';
import { useParams } from 'react-router-dom';
import { styles } from '../../../style';
import { blogs, categories } from '../../../constants';
import { Hero, Card, Newsletter } from '../../../components';

const Category = () => {
	const params = useParams();

	// Find current page category
	const category = categories.find(
		(category) => category.slug === params.title
	);

	//Find all the blogs of current page category
	const categoryBlogs = blogs.filter(
		(blog) => blog.category === category.title
	);

	return (
		<>
			<Hero category={category} />
			<div
				id="category-blogs"
				className={`category-blogs-content ${styles.sectionPaddingX} ${styles.sectionPaddingY}`}
			>
				{categoryBlogs.map((blog) => (
					<Card key={blog.id} blog={blog} type="" />
				))}
			</div>
			<Newsletter/>
		</>
	);
};

export default Category;
