import React, {useState, useEffect ,useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import {styles} from '../../../style';
import {RiFileSettingsFill} from 'react-icons/ri'
import {TableAdd, TableSearch, TableCount, TableRowLimit, Table, FilterReset, Pagination, BlogPreview} from '../../../components/dashboard'
import { blogs } from '../../../constants';
import { IoFilter } from "react-icons/io5";
import { TbArrowsSort } from "react-icons/tb";
import { categories } from '../../../constants';

const Index = () => {
	const navigate = useNavigate();

	//Define the number of rows for table to be displayed
	const[rows, setRows] = useState(10);

	//Define the search query string for table data
	const[searchQuery, setSearchQuery] = useState('');

	//Define filter options status or each filter
	const [isDateFilter, setIsDateFilter] = useState(false);
	const [isCategoryFilter, setIsCategoryFilter] = useState(false);
	const [isRatingsFilter, setIsRatingsFilter] = useState(false);
	const [isSharesFilter, setIsSharesFilter] = useState(false);

	//Define table filter title references
	const dateFilterTitleRef = useRef();
	const categoryFilterTitleRef = useRef();
	const ratingsFilterTitleRef = useRef();
	const sharesFilterTitleRef = useRef();

	//Define seleteced filter options array
	const [selectedFilters, setSelectedFilters] = useState({
		date: '',
		category: '',
		ratings: '',
		shares: ''
	});

	//Define filter reset status
	const [isResetFilter, setIsResetFilter] = useState(false);

	//Define current page & no of pages for pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(5);

	//Define blog view status
	const [isBlogView, setIsBlogView] = useState(false);
	const [currentBlogView, setCurrentBlogView] = useState('');

	//Method to get the selected filter option
	const getSelectedFilter = (title,option) => {
		setSelectedFilters({...selectedFilters, [title] : option})
	} 

	//Method to reset filter 
	const onClickResetFilter = () => {
		setSelectedFilters({
			date: '',
			category: '',
			ratings: '',
			shares: ''
		});
		setIsResetFilter(false);
	}

	//Define the number of table rows to be selected from dropdown
	const rowOptions = [10, 20, 30, 40, 50];

	//Define the table heads
	const tableColumn = [
		{
			id: 1,
			title: 'id',
			filter: false,
			icon: ''
		},
		{
			id: 2,
			title: 'title',
			filter: false,
			icon: ''
		},
		{
			id:3,
			title: 'date',
			filter: true,
			filterOptions: ['newest to oldest', 'oldest to newest'],
			filterStatus: isDateFilter,
			setFilterStatus: setIsDateFilter,
			icon: <TbArrowsSort/>,
			titleRef: dateFilterTitleRef
		},
		{
			id:4,
			title: 'category',
			filter: true,
			filterOptions: categories.map((category) => {
				return category.title
			}),
			filterStatus: isCategoryFilter,
			setFilterStatus: setIsCategoryFilter,
			icon: <IoFilter/>,
			titleRef: categoryFilterTitleRef
		},
		{
			id:5,
			title: 'ratings',
			filter: true,
			filterOptions: ['high to low', 'low to high'],
			filterStatus: isRatingsFilter,
			setFilterStatus: setIsRatingsFilter,
			icon: <IoFilter/>,
			titleRef: ratingsFilterTitleRef
		},
		{
			id:6,
			title: 'shares',
			filter: true,
			filterOptions: ['high to low', 'low to high'],
			filterStatus: isSharesFilter,
			setFilterStatus: setIsSharesFilter,
			icon: <IoFilter/>,
			titleRef: sharesFilterTitleRef
		},
		{
			id:7,
			title: 'actions',
			filter: false,
			icon: ''
		}
	];

	useEffect(() => {
		//Check if any of filter option is selected to show filter reset button
		if(selectedFilters.category !== '' || selectedFilters.date !== '' || selectedFilters.shares !== '' || selectedFilters.ratings !== '') {
			setIsResetFilter(true);
		}

		//Hide filter options on outside click
		//Define call back function on outside click
		const handleOutsideTitleClick = (e) => {
			//Define action on each icon outside click
			tableColumn.forEach((col) => {
				if(col.filterStatus && !col.titleRef.current.contains(e.target)) {
					col.setFilterStatus(false);
				}
			});
		};

		//Define on click
		document.addEventListener('click', handleOutsideTitleClick);

		//Remove event listener
		return () => {
			document.removeEventListener('click', handleOutsideTitleClick);
		}
		// eslint-disable-next-line
	},[isDateFilter, isCategoryFilter, isRatingsFilter, isSharesFilter, currentBlogView]);

	//Handle blog view
	const handleBlogView = () => {
		setIsBlogView(false);
	}

	//Handlers for view, edit  & delete
	const handleOnViewClick = (blog) => {
		setIsBlogView(true);
		setCurrentBlogView(blog);
	}

	const handleOnEditClick = (id) => {
		navigate(`/dashboard/blogs/edit/${id}`);
	}

	const handleOnDeleteClick = (id) => {
		alert('id ' + id + ' requested for delete');
	}
	
	return (
		<div className="dashboard-main">
			<div className="dashboard-main-wrapper">
				<div className={`dashboard-main-heading ${styles.heading4}`}>
					<RiFileSettingsFill className='dashboard-main-heading-icon'/> manage blogs
				</div>
				<div className="dashboard-main-content">
					<div className="dashboard-main-content-header">
						<div className="dashboard-main-content-header-left">
							<TableSearch setSearchQuery={setSearchQuery} searchTag="title"/>
							<TableAdd link='/dashboard/blogs/create'/>
							<FilterReset isResetFilter={isResetFilter} onClickResetFilter={onClickResetFilter}/>
						</div>
						<div className="dashboard-main-content-header-right">
							<TableCount count={blogs.length} name="blogs"/>
							<TableRowLimit rows={rows} setRows={setRows} rowOptions={rowOptions}/>
						</div>
					</div>
					<div className="dashboard-main-content-data">
						<Table tableColumn={tableColumn} tableData={blogs} tableDataType='blogs' getSelectedFilter={getSelectedFilter} handleOnViewClick={handleOnViewClick}  handleOnEditClick={handleOnEditClick} handleOnDeleteClick={handleOnDeleteClick}/>
						<Pagination currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage}/>
					</div>
				</div>
				{isBlogView &&  <BlogPreview handleBlogPreview={handleBlogView} title={currentBlogView.title} category={currentBlogView.category} image={currentBlogView.image} content={currentBlogView.description} date={currentBlogView.date}/>}
			</div>
		</div>
	)
};

export default Index;
